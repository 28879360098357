










































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { namespace } from "vuex-class";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import { formatInstructorName, formatStudentName } from "@/utils/NameUtil";
import EditButton from "@/components/Button/EditButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";

const TheoryLessonModule = namespace("theory-lesson");
const StudentEducationModule = namespace("student-education");

@Component({
  components: {
    AbortButton, SaveButton,
    EditButton,
    FscHorizontalTable,
    FscSimpleCard
  }
})
export default class CostBearerEducationTheoryLessonPreview extends Vue {
  public name = "CostBearerEducationTheoryLessonPreview";

  @TheoryLessonModule.Action("findOne")
  public findTheoryLessonById: any;

  @TheoryLessonModule.Getter("getDataItem")
  public lesson: any;

  @TheoryLessonModule.Action("update")
  public updateTheoryLessonById: any;

  @TheoryLessonModule.Getter("getSuccess")
  public theoryLessonUpdateSuccess: any;

  @StudentEducationModule.Action("findByStudent")
  public studentEducationFindByStudent: any;

  @StudentEducationModule.Getter("getDataList")
  public studentEducations: any;

  @Prop()
  public theoryLesson!: any;

  public licenseClasses!: any;

  protected selectedClass: Array<string> = [];

  public showAction = false;
  public showAdditionalFields = false;

  public studentId: any;

  // @Prop()
  // public student!: any;


  public async mounted() {
    if (this.theoryLesson.id) {
      this.findTheoryLessonById({
        id: this.theoryLesson.id,
        resource: "theory-lesson"
      });

      this.setSelectedClasses();

      await this.studentEducationFindByStudent(this.theoryLesson.student.id);
      this.licenseClasses = [];
      for (let i in this.studentEducations) {
        this.licenseClasses.push(this.studentEducations[i].mainLicenseClass);
      }
    }
  }

  public get assignableClasses() {
    const exclude = ["WBC", "WBD", "BE", "C1E", "D1E", "DE"];  // List of elements to exclude
    return this.licenseClasses.filter((cls: string) => !exclude.includes(cls));
  }

  public onEdit() {
    this.showAction = true;
    this.showAdditionalFields = true;
  }

  public onClose() {
    this.showAction = false;
    this.showAdditionalFields = false;
  }

  public async onSave() {

    const data = {
      id: this.lesson.id,
      selectedClasses: this.selectedClass
    };

    await this.updateTheoryLessonById({
      resource: "/theory-lesson/" + this.lesson.id + "/student-theory-lesson/" + this.theoryLesson.student.id,
      data: data
    });

    if (this.theoryLessonUpdateSuccess) {
      this.$emit("refresh");
      this.onClose();
    }
  }

  public get allowEdit() {
    return this.isBaseTheoryLesson();
  }

  public get instructorName() {
    if (this.lesson.instructor) {
      return formatInstructorName(this.lesson.instructor.firstName, this.lesson.instructor.lastName);
    }
    return "";
  }

  public isBaseTheoryLesson() {
    return this.theoryClassName == "Grundstoff";
  }

  public get theoryClassName() {
    if (this.lesson.theoryClass) {
      return `${this.lesson.theoryClass.name}`;
    }
    return "";
  }

  @Watch("theoryLesson")
  public async onTheoryLessonIdChange(theory: any) {
    if (theory) {
      this.onClose();
      await this.findTheoryLessonById({
        id: theory.id,
        resource: "theory-lesson"
      });
      this.setSelectedClasses();
    }
  }

  protected get studentFullName(): string {
    return this.theoryLesson.student ? formatStudentName(this.theoryLesson.student.firstName, this.theoryLesson.student.lastName) : "";
  }

  protected get createdByFullName() {
    const createdBy = this.lesson?.createdBy;
    return `${createdBy.firstName} ${createdBy.lastName}`;
  }

  protected sourceSignature: any = null;
  protected columns = [
    {
      label: this.$tc("general.customer", 1),
      key: "student"
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "theoryClassName"
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date"
    },
    {
      label: this.$tc("calendar.form_time", 1),
      key: "time"
    },
    {
      label: this.$tc("general.duration", 1),
      key: "duration"
    },
    {
      label: this.$tc("general.driving_instructor", 1),
      key: "instructor"
    },
    {
      label: "Ausbildung Klasse",
      key: "studentTheoryLessonClasses"
    },
    {
      label: this.$t("general.signature"),
      key: "signature"
    }
  ];

  public get getColumns() {

    if (!this.isBaseTheoryLesson()) {
      return this.columns.filter((column) => column.key !== "studentTheoryLessonClasses");
    }
    return this.columns;
  }

  public setSelectedClasses() {
    this.studentId = this.theoryLesson.student.id;
    for (let lesson of this.lesson.studentTheoryLessons) {
      if (parseInt(lesson.studentId) === parseInt(this.studentId) && parseInt(lesson.theoryLessonId) == parseInt(this.lesson.id)) {
        this.selectedClass = lesson.studentTheoryLessonClass;
        break;
      }
    }
  }

  // @Watch("lesson")
  // public async onStudentDocumentChange(): Promise<void> {
  //   if (!this.lesson || !!this.lesson?.studentTheoryLessons === false) return;
  //   const studentId = this.student ? this.student.id : +this.$route.params?.id;
  //   const studentTheoryLesson = this.lesson.studentTheoryLessons.find((lesson: any) => lesson.studentId === studentId);
  //   if (!studentTheoryLesson?.signed || !studentTheoryLesson?.studentSignatureDocumentId) {
  //     this.sourceSignature = "";
  //     return;
  //   }
  //   return await axios({
  //     method: "get",
  //     url: `/student-documents/${studentTheoryLesson.studentSignatureDocumentId}`,
  //     responseType: "arraybuffer",
  //   })
  //     .then(
  //       (response: AxiosResponse) => {
  //         const mimeType = response.headers["content-type"].toLowerCase();
  //         const blob = response.data;
  //         this.sourceSignature =
  //           "data:" +
  //           mimeType +
  //           ";base64," +
  //           //@ts-ignore
  //           new Buffer(blob, "binary").toString("base64");
  //       },
  //       () => {
  //         console.log("Unavailable signature");
  //       }
  //     )
  //     .finally(() => {
  //       //
  //     });
  // }
}
